<template>
  <div class="container">
    <div class="row justify-content-center align-items-center">
      <div class="col-12 col-md-12 col-lg-11 col-xl-10">
        <div class="login-page m-y-40 radius-24">
          <div class="row">
            <div class="col-12 col-lg-6">
              <div
                class="register-section radius-24 d-flex justify-content-center align-items-center flex-column p-y-30 p-x-30"
              >
                <div
                  class="have-account d-flex justify-content-center align-items-center flex-column"
                >
                  <slot name="authHeaders" />
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="login-section p-y-30 p-x-30">
                <slot name="authInputs" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EnterPhone'
}
</script>

<style lang="scss" scoped>
.login-page {
  background-color: #fff;
  // box-shadow: -1px 2px 10px 10px #00000003;
  box-shadow: 0 4px 14px 0 rgba($black-color, 0.2);

  .register-section {
    height: 100%;
    border-top: 1px solid #ccc;
    border-right: 1px solid #ccc;

    [dir='rtl'] & {
      border-right: 0;
      border-left: 1px solid #ccc;
    }

    .have-account {
      text-align: center;

      p {
        font-size: 16px;
        line-height: 30px;
      }
    }
  }
}

.errorMessage {
  height: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  font-weight: bold;
}
</style>
