<template>
  <div class="enter-phone-number h-400">
    <div class="content white-color-bg p-y-48 p-x-42 radius-14">
      <template>
        <h3 class="bold-font fs-22 black-color-text">
          {{ $t('forms.welcome') }}
          <div v-if="errors.length">
            <ul
              v-for="(error, index) in errors"
              :key="index"
            >
              <li>{{ error }}</li>
            </ul>
          </div>
        </h3>
        <form
          class="mt-4"
          @submit.prevent="submitForm"
        >
          <label
            for="phone"
            class="bold-font"
          >{{ $t('forms.phone') }}</label>

          <BaseTextInput
            :value="form.phone"
            :placeholder="$t('forms.enterPhone')"
            input-id="phone"
            class="m-b-12"
            type="text"
            maxlength="11"
            :has-error="errors.has('phone')"
            :message="errors.get('phone')"
            @reset="errors.clear()"
            @submit="validatePhoneNumber"
            @isNumber="isNumber"
            @update-value="updatePhoneNumber"
          />

          <div class="number-counter">
            11 / {{ form.phone.length }}
          </div>

          <base-button
            :text="$t('forms.continue')"
            class="m-t-12"
            bg-color-class="main-color-bg"
            is-medium
          />
        </form>
      </template>
    </div>
  </div>
</template>

<script>
import Form from '@/services/Form'
import Errors from '@/services/Errors'

export default {
  name: 'EnterPhoneNumber',
  data () {
    return {
      form: new Form({
        phone: ''
      }),
      counter: 0,
      errors: new Errors()
    }
  },

  computed: {
    userPhone () {
      return this.$store.getters['Globals/userPhone']
    }
  },

  methods: {
    updatePhoneNumber (val) {
      this.form.phone = val.value
    },

    isNumber (event) {
      if (!/\d/.test(event.key) && event.key !== '.') return event.preventDefault()
    },

    submitForm () {
      this.validatePhoneNumber()
      this.$store
        .dispatch('Globals/loginByPhone', {
          phone: this.form.phone,
          app_version: ''
        })
        .then(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
@import './authStyle';
</style>
