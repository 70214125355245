<template>
  <AuthLayout>
    <div class="w-100">
      <div class="container">
        <EnterPhoneNumber />
      </div>
    </div>
  </AuthLayout>
</template>

<script>
import AuthLayout from '@/layouts/AuthLayout.vue'
import EnterPhoneNumber from '@/components/auth/register/EnterPhoneNumber.vue'
export default {
  name: 'EnterPhoneNumberPage',
  components: { AuthLayout, EnterPhoneNumber }
}
</script>
