<template>
  <AuthSlot>
    <template #authHeaders>
      <h3 class="bold-font">
        {{ headerTitle }}
      </h3>
      <p class="regular-font light-gray-color-text m-y-10">
        {{ text }}
      </p>

      <BaseButton
        color-class="white-color-text"
        bg-color-class="black-color-bg"
        :text="btnText"
        is-route
        is-medium
        :route-url="routeUrl"
        fixed-width="180px"
      />
    </template>
    <template #authInputs>
      <form @submit.prevent="validateData">
        <h3 class="medium-font black-color-text fs-18 m-b-22">
          {{ title }}
        </h3>
        <BaseTextInput
          :value="form.phone"
          input-id="phone"
          :label="$t('forms.phone')"
          class="m-b-18"
          :has-error="errors.has('phone')"
          :message="errors.get('phone')"
          isRadius
          @reset="errors.clear()"
          @submit="validateData"
          @update-value="getLoginData"
        />

        <div
          v-if="errorMessage"
          class="errorMessage"
        >
          {{ errorMessage }}
        </div>

        <BaseButton
          color-class="white-color-text"
          bg-color-class="main-color-bg"
          :text="$t('forms.continue')"
          is-medium
          @action="submitForm"
        />
      </form>
    </template>
  </AuthSlot>
</template>

<script>
import AuthSlot from './AuthSlot'
import Form from '@/services/Form'
import Errors from '@/services/Errors'

export default {
  name: 'EnterPhone',
  components: { AuthSlot },
  props: {
    headerTitle: {
      type: String,
      required: false,
      default: ''
    },

    text: {
      type: String,
      required: false,
      default: ''
    },

    btnText: {
      type: String,
      required: false,
      default: ''
    },

    routeUrl: {
      type: [String, Object],
      required: false
    },

    title: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      form: new Form({
        phone: ''
      }),
      errorMessage: '',
      errors: new Errors()
    }
  },
  methods: {
    submitForm () {
      if (this.validateDataForLogin()) {
        delete this.form.originalData
        this.$store
          .dispatch('Globals/loginByPhone', {
            phone: this.form.phone
          })
          .then(() => {})
      }
    }
  }
}
</script>
