<template>
  <AuthLayout>
    <EnterPhone />
  </AuthLayout>
</template>

<script>
import AuthLayout from '@/layouts/AuthLayout'
import EnterPhone from '@/components/auth/EnterPhone'

export default {
  name: 'EnterPhoneView',
  components: { AuthLayout, EnterPhone }
}
</script>
